// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/agreement/provider.ts"
);
import.meta.hot.lastModified = "1740495432000";
}
// REMIX HMR END

import {
  AgreementListOptions,
  AgreementQuery,
  AgreementsQuery,
  SignAgreementMutation,
  SignAgreementsMutation,
} from '~storefront/generated/graphql'

import { QueryOptions, sdk } from '~storefront/graphqlWrapper'

export const signAgreement = (
  orderLineId: string,
  sign?: boolean,
  queryOptions?: QueryOptions,
): Promise<SignAgreementMutation> => {
  return sdk.signAgreement({ orderLineId, sign }, queryOptions)
}

export const signAgreements = (
  orderLineIds: string[],
  sign?: boolean,
  queryOptions?: QueryOptions,
): Promise<SignAgreementsMutation> => {
  return sdk.signAgreements({ orderLineIds, sign }, queryOptions)
}

export const list = (
  options?: AgreementListOptions,
  queryOptions?: QueryOptions,
): Promise<AgreementsQuery> => {
  return sdk.agreements({ options }, queryOptions)
}

export const get = (
  id: string,
  options?: QueryOptions,
): Promise<AgreementQuery> => {
  return sdk.agreement({ id }, options)
}
